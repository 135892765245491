import React from 'react';
import { Layout, SEO } from 'components';
import { WorkList } from 'containers';

const WorkPage = () => (
    <Layout noBackground>
        <SEO title='Work' />
        <WorkList />
    </Layout>
)

export default WorkPage;